import React from 'react'
import About from './components/About'
import Contact from './components/Contact'
import Home from './components/Home'
import Interests from './components/Interests'
import Navbar from './components/Navbar'
import Projects from './components/Projects'
import Skills from './components/Skills'

const App = () => {

  
  return (
    <>
    <Navbar/>
    <Home/>
    <About/>
    <Skills />
    <Projects/>
    <Interests/>
    <Contact/>
    </>
  )
}

export default App
