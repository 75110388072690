import React from 'react'
import Image from '../assets/world-map.png'

const Home = () => {

  return (
    <section className='hero is-large' id='home-full'>
        <div className='hero-body' id='home-columns'>
          <div id='home-text'style={{  }}>
            <p id='home-intro'>HI THERE&nbsp; 👋 &nbsp;&nbsp;I'M</p>
              <div id='home-name'>Issra Hashim</div>
            <p id='home-role'>SOFTWARE DEVELOPER&nbsp;&nbsp;</p>
          </div>
          <span id='emoji'> 👩🏻‍💻</span>
        </div>
    </section>
  )
}


export default Home