import React from 'react'
import ProfilePicture from '../assets/Profile.jpg'


const About = () => {

  return (
    <section className='hero is-medium' id='about-full'>
      <div className='hero-body' id='about-columns'>
        <div id='about-image-column'>
        <img src={ProfilePicture} id='about-image' alt='Issra Hashim profile picture'/>
        </div>
        <div id='about-title-column'>
        <span id='about-title'>ABOUT ME
        <hr />
        </span>
        <p>
        I'm a software engineer with a humanities and arts background and am looking to combine my technical experience and understanding of the creative sector to build accessible, reliable and empowering technologies.
        <br/>
        <br/>
        I got into coding very suddenly. Speaking with other developers I realised the power and creativity it requires and became passionate about mastering this skill. 
        <br/>
        <br/>
        I love giving life to data and seeing the different ways lines of code can be ordered and displayed to create a unique user experience. 
        <br/>
        </p>
        </div>
      </div>
    </section>
  )
}

export default About