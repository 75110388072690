import React from 'react'
import Github from '../assets/icons/github-original.svg'
import Linkedin from '../assets/icons/Linkedin.png'
import Outlook from '../assets/icons/microsoft.png'

const Contact = () => {

  const copy = (event) => {
    navigator.clipboard.writeText(event.target.innerText)
  }

  return (
    <section className='hero' id='contact-full'>
      <div className='hero-body' id='contact-info'>
        <div id='contact-title'>CONTACT ME</div>
        <div id='contact-column'>
          <div id='contact-address'>
            <a href='mailto: issrahashim@hotmail.fr'>
              <img src={Outlook} alt='outlook' id='contact-github' />
            </a>
            <br/>
            <br/>
            <a onClick={copy}>issrahashim@hotmail.fr</a>
            </div>
          <div id='contact-address'>
            <a href='https://www.linkedin.com/in/issrahashim/' target="_blank">
              <img src={Linkedin} alt='linkedin' id='contact-github'/>
            </a>
            <br/>
            <br/>
            <a onClick={copy}>linkedin.com/in/issrahashim</a>
            </div>
          <div id='contact-address'>
            <a href='https://github.com/IssraHashim' target="_blank">
              <img src={Github} alt='github' id='contact-github'/>
            </a>
            <br/>
            <br/>
            <a onClick={copy}>github.com/IssraHashim</a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact

