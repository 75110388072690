import React, { useState, useEffect } from 'react'
import Logo from '../assets/logo2.png'



const Navbar = () => {
  // const [scrollState, setScrollState] = useState("big")
  const [displayOptions, setDisplayOptions] =useState(false)

  // useEffect(() => {
  //   document.addEventListener("scroll", e => {
  //     const scrolled = document.scrollingElement.scrollTop
  //     if (scrolled > 0) {
  //       if (scrollState !== "small") {
  //         setScrollState("small")
  //       }
  //     } else if (scrollState !== "large"){
  //       setScrollState("large")
  //     }
  //   })
  // }, [scrollState])

  const handleClick =(event) => {
    if (event.target.text === 'Home') {
      window.scrollTo(0, 0)
    }
    if (event.target.text === 'About') {
      window.scrollTo(0, 700)
    }
    if (event.target.text === 'Portfolio') {
      window.scrollTo(0, 2700)
    }
    if (event.target.text === 'Contact Me') {
      window.scrollTo(0, 4500)
    }
  }


  return (
    // scrollState === 'small' ?
    // <section className='navbar is-fixed-top is-transparent' id='navbar-full'>
    //   <div onClick={() => setDisplayOptions(!displayOptions)}><img src={Logo} id='navbar-logo'/></div>
    //   <div id='navbar-sections'>
    //   {displayOptions &&
    //   <>
    //     <div ><a onClick={(event)=> handleClick(event)}>Home</a></div>
    //     <div><a onClick={(event)=> handleClick(event)}>About</a></div>
    //     <div><a onClick={(event)=> handleClick(event)}>Portfolio</a></div>
    //     <div><a onClick={(event)=> handleClick(event)}>Contact Me</a></div>
    //   </>
    //   }
    //   </div>
    // </section>
    // :
    <section className='navbar is-fixed-top is-transparent' id='navbar-full'>
      <div>
        <p id='logo-initials'>I</p>
        </div>
      <div id='navbar-sections'>
      <div ><a onClick={(event)=> handleClick(event)}>Home</a></div>
      <div><a onClick={(event)=> handleClick(event)}>About</a></div>
      <div><a onClick={(event)=> handleClick(event)}>Portfolio</a></div>
      <div><a onClick={(event)=> handleClick(event)}>Contact Me</a></div>
      </div>
    </section>
  )
}

export default Navbar