import React from 'react'
import github from '../assets/icons/github-original.svg'

const Projects = () => {
  return (
    <section className='hero is-medium' id='projects-full'>
      <div className='hero-body' >
      <p id='about-title'>MY PORTFOLIO</p>
        <hr />
        <div id='projects-column'>
          <div id='card1'>
            <div id='card-image'>
              <div id="image">
                <p id='project-text'>A Solo grid-based game built using JavaScript, HTML and CSS. <br/>
                  <div id='project-links'>
                    <button id='visit-website' ><a href='https://issrahashim.github.io/SEI-Project-1/' target="_blank">Let's play!</a>
                    </button>
                    <button  id='visit-github'>
                      <a href='https://github.com/IssraHashim/SEI-Project-1' target="_blank"><img src={github} id='link-logo' /></a>
                    </button>
                  </div>
                </p>
              </div>
            </div>
          </div>
          <div id='card2'>
            <div id='card-image'>
              <div id="image">
                <p id='project-text'>A pair-programming hackathon where we built an app using a public NASA api using HTML, React Hooks, Express, JavaScript, Bulma, CSS, and Node.js. <br/>
                <div id='project-links'>
                  <button id='visit-website'>
                    <a href='https://astronomy-pictures.netlify.app/' target="_blank">Let's explore!</a>
                  </button>
                  <button  id='visit-github'>
                    <a href='https://github.com/IssraHashim/SEI-Project-2' target="_blank"><img src={github} id='link-logo'/></a>
                    </button>
                </div>
                </p>
              </div>
            </div>
          </div>
          <div id='card3'>
            <div id='card-image'>
              <div id="image">
                <p id='project-text'>A group project building a full-stack MERN app with CRUD functionality.<br/>
                  <div id='project-links'>
                    <button id='visit-website'>
                      <a href='https://platester-app.onrender.com/' target="_blank">Let's cook!</a>
                    </button>
                    <button  id='visit-github'>
                    <a href='https://github.com/IssraHashim/SEI-Project-3' target="_blank"><img src={github} id='link-logo'/></a>
                    </button>
                  </div>
                </p>
              </div>
            </div>
          </div>
          <div id='card4'>
            <div id='card-image'>
              <div id="image">
                <p id='project-text'>A Solo project building a full stack app with React, Python Django API and postgreSQl.<br/>
                  <div id='project-links'>
                    <button id='visit-website'>
                      <a href='https://bookopedia.onrender.com/' target="_blank">Let's read!</a>
                    </button>
                    <button  id='visit-github'>
                      <a href='https://github.com/IssraHashim/SEI-Project-4' target="_blank"><img src={github} id='link-logo'/></a>
                    </button>
                  </div>
                  </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Projects