import React from 'react'
import Html from '../assets/icons/html5-plain.svg'
import Css from '../assets/icons/css3-plain.svg'
import JavaScript from '../assets/icons/javascript-plain.svg'
import react from '../assets/icons/react-original.svg'
import Bulma from '../assets/icons/bulma-plain.svg'
import Boostrap from '../assets/icons/bootstrap-plain.svg'
import Node from '../assets/icons/nodejs-plain.svg'
import Mongo from '../assets/icons/mongodb-plain.svg'
import Express from '../assets/icons/express-original.svg'
import Python from '../assets/icons/python-plain.svg'
import Django from '../assets/icons/django-plain.svg'
import Postgresql from '../assets/icons/postgresql-plain.svg'
import yarn from '../assets/icons/yarn-original.svg'
import npm from '../assets/icons/npm-original-wordmark.svg'
import git from '../assets/icons/git-plain.svg'
import github from '../assets/icons/github-original.svg'
import Docker from '../assets/icons/docker.svg'
import Kubernetes from '../assets/icons/kubernets.svg'
import AWS from '../assets/icons/aws.svg'


const Skills = () => {

  return (
    <section className='hero is-medium' id='skills-full'>
      <div className='hero-body' style={{paddingTop: '50px', paddingBottom: '50px'}}>
      <p id='about-title'>SKILLS</p>
        <hr />
        <p id='language-title'>Tools and programming languages</p>
        <br/>
        <br/>
        <div id='icons-pic'>
          <div id='skill-columns'>
            <div id='skill-section'>Backend</div>
            <div id='individual-icon'>
              <img src={Python} alt='python' id='icon-skills'/>
              <p id='skill-name'>Python</p>
            </div>
            <div id='individual-icon'>
              <img src={Node} alt='node' id='icon-skills'/>
              <p id='skill-name'>Node.js</p>
            </div>
            <div id='individual-icon'>
              <img src={Express} alt='express' id='icon-skills'/>
              <p id='skill-name'>Express</p>
            </div>
            <div id='individual-icon'>
              <img src={Django} alt='django' id='icon-skills'/>
              <p id='skill-name'>Django</p>
            </div>
            <div id='individual-icon'>
              <img src={Postgresql} alt='postgresql' id='icon-skills'/>
              <p id='skill-name'>PostgreSQL</p>
            </div>
            <div id='individual-icon'>
              <img src={Mongo} alt='Mongo' id='icon-skills'/>
              <p id='skill-name'>MongoDB</p>
            </div>
            <div id='individual-icon'>
              <img src={Docker} alt='Mongo' id='icon-skills'/>
              <p id='skill-name'>Docker</p>
            </div>
            <div id='individual-icon'>
              <img src={Kubernetes} alt='Mongo' id='icon-skills'/>
              <p id='skill-name'>Kubernetes</p>
            </div>
            <div id='individual-icon'>
              <img src={AWS} alt='Mongo' id='icon-skills'/>
              <p id='skill-name'>AWS</p>
            </div>
          </div>
          <div id='skill-columns'>
              <div id='skill-section'>Frontend </div>
            <div id='individual-icon'>
              <img src={JavaScript} alt='JavaScript' id='icon-skills'/>
              <p id='skill-name'>JavaScript</p>
            </div>
            <div id='individual-icon'>
              <img src={react} alt='react' id='icon-skills'/>
              <p id='skill-name'>React</p>
            </div>
            <div id='individual-icon'>
              <img src={Html} alt='html'id='icon-skills'/>
              <p id='skill-name'>HTML</p>
            </div>
            <div id='individual-icon'>
              <img src={Css} alt='css' id='icon-skills'/>
              <p id='skill-name'>CSS</p>
            </div>
            <div id='individual-icon'>
              <img src={Bulma} alt='bulma' id='icon-skills'/>
              <p id='skill-name'>Bulma</p>
            </div>
            <div id='individual-icon'>
              <img src={Boostrap} alt='bootstrap' id='icon-skills'/>
              <p id='skill-name'>Bootstrap</p>
            </div>
            </div>
            <div id='skill-columns'>
              <div id='skill-section'>Other</div>
            <div id='individual-icon'>
              <img src={yarn} alt='yarn' id='icon-skills'/>
              <p id='skill-name'>yarn</p>
            </div>
            <div id='individual-icon'>
              <img src={npm} alt='npm' id='icon-skills'/>
              <p id='skill-name'>npm</p>
            </div>
            <div id='individual-icon'>
              <img src={git} alt='git' id='icon-skills'/>
              <p id='skill-name'>git</p>
            </div>
            <div id='individual-icon'>
              <img src={github} alt='github' id='icon-skills'/>
              <p id='skill-name'>GitHub</p>
            </div> 
            </div>
          </div>


        <br/>
        <br/>
        <p id='language-title'>Languages</p>
        <br />
        <div id='skills-languages'>
          <p><strong>Native</strong> 
          <br/>
            <p id='skills-language-individual'> 
            <p>French 🇫🇷&nbsp;</p>
            <p>Arabic 🇮🇶&nbsp;</p>
            <p>English 🇬🇧&nbsp;</p></p>
            </p>
          <p style={{ height: '15px' }}/>
          <p><strong>Intermediate</strong> 
            <p id='skills-language-individual'>
            <p>Spanish 🇪🇸&nbsp;</p>
            <p>Russian 🇷🇺&nbsp;</p></p>
            </p>
        </div>

      </div>
    </section>
  )
}
export default Skills