import React from 'react'

const Interests = () => {
  return (
    <section className='hero is-large' id='interests-full'>
      <div id='hero-body' >
        <div id='interests-title'>MY INTERESTS</div>
        <hr/>
        <div id='interests-columns'>
        <div className='interest'><strong>Piano , Guitar </strong><br/>
        I play the piano every other day to relax and take a no-screen break.</div>
        <div className='interest'><strong>Philosophy and Social anthropology</strong><br/>
        I love going to conferences on recent publications or debates in this field.</div>
        <div className='interest'><strong>Cooking</strong> <br/>
        I enjoy cooking new or traditional recipes and playing around with new flavors.</div>
        </div>
      </div>
    </section>
  )
}

export default Interests